import { api } from '@culture/common-helpers/request';
import * as types from './types';

export const getPossibleFilterValues = (url, params) => (dispatch) => {
	dispatch({
		type: types.GET_FILTER_VALUES_START
	});

	api(url, { ...params, cached: true })
		.then((res) => {
			dispatch({
				type: types.GET_FILTER_VALUES_SUCCESS,
				payload: res
			});
		})
		.catch((error) =>
			dispatch({
				type: types.GET_FILTER_VALUES_ERROR,
				payload: error
			})
		);
};

export const resetFilterValues = () => ({
	type: types.RESET_FILTER_VALUES
});
