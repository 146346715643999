import * as appSelectors from './selectors';

export { appSelectors };

const initialState = {
	redirectUrl: ''
};

const appReducer = (state = initialState) => state;

export default appReducer;
