import _ from 'underscore';
import { statusesHash } from '@culture/utils/helpers/statuses';

import { filterQuery } from '@culture/common-helpers/url';
import * as actions from './actions';

export const getInstitutesCatalog = ({ ...params }) => async (dispatch) => {
	const institutesParams = filterQuery({
		..._(params).pick('limit', 'query', 'location', 'cleanCache', 'page'),
		fields: ['_id', 'type', 'title', 'name', 'thumbnailFile', 'locale'].join(),
		statuses: [statusesHash.published],
		isPushkinsCard: true,
		sort: '-views',
		cached: true,
		types: [params.types]
	});

	await dispatch(actions.getInstitutes(institutesParams));
};

export default {
	getInstitutesCatalog
};
