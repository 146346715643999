import App from 'next/app';
import PropTypes from 'prop-types';
import getConfig from 'next/config';
import { Provider } from 'react-redux';
import { ParallaxProvider } from 'react-scroll-parallax';
import { GlobalElementsProvider } from 'contexts/GlobalElementsContext';
import { useStore } from 'store';
import { ImageFormatProvider } from 'contexts/ImageFormatContext';

import 'styles/global.scss';

function FrontendApp({ Component, pageProps, imageFormat, redirectUrl }) {
	const store = useStore({
		...pageProps.initialReduxState,
		app: { redirectUrl }
	});

	return (
		<ParallaxProvider>
			<Provider store={store}>
				<GlobalElementsProvider>
					<ImageFormatProvider imageFormat={imageFormat}>
						<Component {...pageProps} />
					</ImageFormatProvider>
				</GlobalElementsProvider>
			</Provider>
		</ParallaxProvider>
	);
}

if (!process.browser) {
	const {
		serverRuntimeConfig,
		publicRuntimeConfig: { baseRedirectUrl }
	} = getConfig();

	// eslint-disable-next-line
	const serverLogger = require('../server/utils/logger');

	const acceptTypeRegExp = /(?<=^|,).+?(?=$|,|;)/g;

	// Only uncomment this method if you have blocking data requirements for
	// every single page in your application. This disables the ability to
	// perform automatic static optimization, causing every page in your app to
	// be server-side rendered.
	//

	FrontendApp.getInitialProps = async (appContext) => {
		const { req, res } = appContext.ctx;

		res.setHeader('Cache-Control', serverRuntimeConfig.cacheControl.default);

		serverLogger.logInfo(
			`${new Date()} Request URL: ${req.url}; Method: ${req.method};`
		);

		// calls page's `getInitialProps` and fills `appProps.pageProps`
		const appProps = await App.getInitialProps(appContext);

		const acceptHeader = req.headers.accept || '';
		const acceptedTypes = new Set(acceptHeader.match(acceptTypeRegExp));
		const imageFormat = serverRuntimeConfig.imageFormats.find((format) =>
			acceptedTypes.has(`image/${format}`)
		);

		const getRedirectUrl = (url) => {
			switch (url) {
				case 'staging1-pushka.fabit.ru':
					return baseRedirectUrl.staging;
				default:
					return baseRedirectUrl.production;
			}
		};
		const redirectUrl = getRedirectUrl(req?.headers?.host);

		return {
			...appProps,
			imageFormat,
			redirectUrl
		};
	};
}

FrontendApp.propTypes = {
	Component: PropTypes.elementType.isRequired,
	pageProps: PropTypes.object,
	imageFormat: PropTypes.string,
	redirectUrl: PropTypes.string
};

FrontendApp.defaultProps = {
	pageProps: {}
};

export default FrontendApp;
