import { api as apiRequest } from '@culture/common-helpers/request';
import * as types from './types';

export const getPublications = (params) => async (dispatch) => {
	dispatch({
		type: types.GET_PUBLICATIONS
	});

	try {
		const publications = await apiRequest('publications', params);

		dispatch({
			type: types.GET_PUBLICATIONS_SUCCESS,
			payload: publications
		});
	} catch (error) {
		dispatch({
			type: types.GET_PUBLICATIONS_FAILED,
			payload: error
		});
	}
};

export default {
	getPublications
};
