import _ from 'underscore';
import { statusesHash } from '@culture/utils/helpers/statuses';
import getConfig from 'next/config';

import * as actions from './actions';

const { publicRuntimeConfig } = getConfig();
const { pushkinCardPublicationsRubricPath } = publicRuntimeConfig.entities;

export const getPublicationsCatalog = ({ ...params }) => async (dispatch) => {
	const publicationsParams = _({
		..._(params).pick('cleanCache', 'limit'),
		fields: ['_id', 'type', 'title', 'name', 'thumbnailFile'].join(),
		statuses: [statusesHash.published],
		rubricPaths: [pushkinCardPublicationsRubricPath],
		sort: '-publishDate',
		cached: true
	}).extend(params);

	await dispatch(actions.getPublications(publicationsParams));
};

export default {
	getPublicationsCatalog
};
