import { api as apiRequest } from '@culture/common-helpers/request';
import * as types from './types';

export const getInstitutes = (params) => async (dispatch) => {
	dispatch({
		type: types.GET_INSTITUTES
	});

	try {
		const institutes = await apiRequest('institutes', params);

		dispatch({
			type: types.GET_INSTITUTES_SUCCESS,
			payload: institutes
		});
	} catch (error) {
		dispatch({
			type: types.GET_INSTITUTES_FAILED,
			payload: error
		});
	}
};

export default {
	getInstitutes
};
